<template>
  <div>
    <!-- Filter Dialog -->
    <v-dialog v-model="filterDialog" class="cdz" max-width="570px" style="z-index:1000 !important">
      <v-card width="570px" height="100vh" class="filterDialogClass">
        <v-row class="mt-4 mx-1">
          <v-col>
            <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
              Filter
            </div>
          </v-col>
          <v-col class="d-flex justify-end">
            <div @click="clearFilter" class="clear-filter-btn">
              CLEAR FILTER
            </div>
          </v-col>
        </v-row>
        <v-tabs vertical>
          <div class="ml-3 mt-4 mr-4 filterLine">
            <!-- <v-tab key="stage" class="black--text justify-start" active-class="activeTab">
              Stage
              <span v-if="selectedStageFilter.length > 0" class="ml-1">
                ({{ selectedStageFilter.length }})</span>
            </v-tab> -->
            <!-- <v-tab key="CoreSkillsSubject" class="black--text justify-start" active-class="activeTab">
              League
              <span v-if="selectedLeagueFilter.length > 0" class="ml-1">
                ({{ selectedLeagueFilter.length }})</span>
            </v-tab> -->
            <v-tab key="status" class="black--text justify-start" active-class="activeTab">
              Status
              <span v-if="selectedStatusFilter.length > 0" class="ml-1">
                ({{ selectedStatusFilter.length }})</span>
            </v-tab>
          </div>

          <!-- <v-tab-item key="stage">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedStageFilter" active-class="active-chip" column :multiple="true">
                  <v-chip v-for="(stage, index) in stageOptions" :key="index" :value="stage" elevated>
                    {{ stage }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item> -->

        <v-tab-item key="status">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedStatusFilter" active-class="secondary" column :multiple="true">
                  <v-chip v-for="(status, index) in statusOptions" :key="index" :value="status" elevated>
                    {{ status }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item>


          <!-- <v-tab-item key="CoreSkillsSubject">
            <v-card>
              <v-card-text>
                <v-chip-group v-model="selectedLeagueFilter" active-class="secondary" column :multiple="true">
                  <v-chip v-for="(league,index) in leagueOptions" 
                  :key="index"
                  :value="league"
                  elevated>
                    {{league}}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
        </v-tabs>
        <div class="filterDialogButton">
          <v-card-actions class="px-6 pb-0">
            <v-spacer></v-spacer>
            <v-btn rounded outlined class="btn outline-btn pa-4" @click="closeFilter">Cancel</v-btn>
            <v-btn class="btn primary-btn pa-4" :disabled="selectedStatusFilter.length == 0"
              @click="filterTeachers(selectedStatusFilter), getFilterCount()">Apply</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-col>
    <v-card v-if="id"  height="64px" width="100%"
        class="mx-4 pl-3 pt-5 pr-4 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <span @click="$router.push('/campaign')" class="breadcrumb-text-unselected underline-on-hover cursor">Campaigns</span>

          <span><v-icon class="breadcrumb-arrow">mdi-chevron-right</v-icon></span>
          <span @click="$router.push('/campaignUser?id='+id+'&campaignType=JOB_SEEKER')" class="breadcrumb-text underline-on-hover cursor">{{ campaignName }}</span>
          <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important"
          >mdi-chevron-down</v-icon>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center fixLog">
          <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="24px" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>
      <v-card v-else  height="64px" width="100%"
        class="mx-4 pl-3 pt-5 pr-4 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <span @click="$router.push('/')" class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>

          <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/jobseekersappraisal')">No. of users recommended</v-list-item>
              <v-list-item @click="$router.push('/jobseekerspip')">No. of users rejected</v-list-item>
              <v-list-item @click="$router.push('/jobseekerstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center fixLog">
          <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="24px" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pl-1 pr-7 ml-3" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card class="rounded-lg pr-0 pl-0 cardBorder" width="100%" height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      JOB SEEKERS
                    </p>
                  </v-card-title>

                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text">Users Registered/Invited </span>

                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="invited"
                      propertyEnd="screeningStarted" funnelColor="#E0ED4B" />
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.invited -
                            funnelJobSeekersData.screeningStarted,
                            funnelJobSeekersData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card class="rounded-lg cardBorder" width="100%" height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text">Attempted </span>
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="screeningStarted"
                      propertyEnd="screeningPassed" funnelColor="#C3ED4B" />
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.screeningStarted -
                            funnelJobSeekersData.screeningPassed,
                            funnelJobSeekersData.screeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card class="rounded-lg cardBorder" width="100%" height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text">Passed</span>
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="screeningPassed"
                      propertyEnd="mainsStarted" funnelColor="#B0D644" />
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.screeningPassed -
                            funnelJobSeekersData.mainsStarted,
                            funnelJobSeekersData.screeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card class="rounded-lg cardBorder" width="100%" height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>


                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text">Attempted </span>
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="mainsStarted"
                      propertyEnd="mainsPassed" funnelColor="#6AD644" />

                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.mainsStarted -
                            funnelJobSeekersData.mainsPassed,
                            funnelJobSeekersData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card class="rounded-lg cardBorder" width="100%" height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text">Passed</span>
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="mainsPassed"
                      propertyEnd="demoSubmitted" />
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.mainsPassed -
                            funnelJobSeekersData.demoSubmitted,
                            funnelJobSeekersData.mainsPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card class="rounded-lg cardBorder" width="100%" height="30vh">
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">DEMO</p>
                  </v-card-title>



                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text">Submitted</span>
                    <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyEnd="interviewSubmitted"
                      propertyBegin="demoSubmitted" />

                    <!-- <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon" />
                      <div class="red--text pl-2" style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelJobSeekersData.demoSubmitted -
                            funnelJobSeekersData.interviewSubmitted,
                            funnelJobSeekersData.demoSubmitted
                          )
                        }}%
                      </div>
                    </div> -->
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="6" md="6" sm="4" class="pl-1">
              <div class="text-h6 font-weight-bold highEmphasis">
                <!-- Users to be Recommended ({{ apraisals }}) -->
                No. of Jobseekers Recommended for Interview({{ passedJobSeekersCount }})
              </div>
            </v-col>

            <v-col cols="6" md="6" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined @click="showfilterdialogue" :disabled="topTeacherInCluster.length == 0"
                class="btn outline-btn mx-3"
                :class="topTeacherInCluster.length == 0 ? 'outline-disabled-btn' : ''"
                ><v-icon>mdi-tune</v-icon>
                <span v-if="filterCount > 0">FILTER({{ filterCount }})</span>
                <span v-else>FILTER</span>
              </v-btn>
              <!-- <v-btn depressed rounded outlined class="border-button mx-3"><img src="../../public/svgs/SORT.svg"
              alt="" />SORT</v-btn> -->

              <!-- <v-btn
                class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded @click="downloadJobSeekersAppraisal"
                :disabled="topTeacherInCluster.length == 0" class="btn outline-btn mx-3"
                :class="topTeacherInCluster.length == 0 ? 'outline-disabled-btn' : ''"
                ><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">S.No</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <th class="head">School Name</th>
                  <th class="head">Stage</th>
                  <th class="head">Status</th>
                  <th class="head">Screening</th>
                  <th class="head">Mains</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterTeachers" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <td class="t-data">{{ teacher.schoolName }}</td>
                  <td class="t-data">
                    {{
                      teacher?.stage === "Screening" ||
                        teacher?.stage === "Mains"
                        ? teacher.stage
                        : "Invited"
                    }}
                  </td>
                  <td class="t-data">
                    <v-chip class="success--text success-01" outlined v-if="teacher?.status === 'PASSED' ||
                      teacher?.status === 'PASSED'
                    ">
                      <div class="dot-pass"></div>PASSED
                    </v-chip>
                    <!-- <template v-else-if="teacher?.status === 'INVITED'">INVITED</template> -->
                    <template v-else>  <v-chip class="fail-color" text-color="#ff0505" outlined
                    >
                      <div class="dot-fail"></div>FAILED
                    </v-chip></template>

                  </td>
                  <td class="t-data">
                    {{ teacher?.screeningTotal }}
                  </td>
                  <td class="t-data">
                    {{ teacher?.mainsTotal }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import FunnelChartCommon from "./FunnelChartCommon.vue";

import Papa from "papaparse";
// import LeagueController from "@/controllers/LeagueController";

export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon
  },
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      schools: [],
      schoolClusterTeacher: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      campaignName: "",
      chartData: {},
      jobSeekerChartData: {},
      emptyJobSeekerChartData: {
        jobSeekerInvited: 0,
        jobSeekerScreeningStarted: 0,
        jobSeekerScreeningPassed: 0,
        jobSeekerScreeningFailed: 0,
        jobSeekerMainsStarted: 0,
        jobSeekerMainsPassed: 0,
        jobSeekerMainsFailed: 0,
        jobSeekersDemoSubmitted: 0,
        jobSeekersInterviewSubmitted: 0,
      },
      recommended: 0,
      jobSeekerRejected: 0,
      jobSeekerTBD: 0,

      funnelJobSeekersData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0,
      },

      filterDialog: false,
      filterCount: 0,
      appliedFilter: false,
      selectedStageFilter: [],
      filteredTeachers: [],
      selectedStatusFilter:[],
      statusOptions: ["PASSED","FAILED"],
      stageOptions: ["Screening", "Mains"],
      // leagueOptions:["Gold","Silver","Bronze"],
      // leagueOptions:[],

      search: "",
      searchedResults: [],
      appliedSearch: false,
      passedJobSeekersCount: 0,
      rejectedJobSeekersCount: 0,
      tdbJobSeekersCount: 0,
      otherJobSeekersCount: 0,
      jobSeekerList: [],
    };
  },
  computed: {
    displayedClusterTeachers() {
      if (this.appliedFilter == false) {
        if (this.appliedSearch == true) {
          return this.searchedResults
        }
        else {
          return this.topTeacherInCluster;
        }
      }
      else if (this.appliedFilter == true) {
        if (this.appliedSearch == true) {
          return this.searchedResults
        }
        else {
          return this.filteredTeachers;
        }
      }
    },
  },
  watch: {
    search(newValue) {
      if (newValue == '' || newValue == null) {
        this.appliedSearch = false;
      }
      else {
        this.searchData(newValue);
      }
    },
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async newJobSeekerFunnelChartDataListener() {
      const dashboardJobSeekersRef = this.id ? doc(db,"dashboards","management-dashboard-001","campaigns",this.id) : doc(db, "dashboards", this.$store.state.role.id + "-jobseekers-dashboard", "results", "campaignresults")

      // const dashboardJobSeekersRef=doc(db, "dashboards", "12345-jobseekers-dashboard", "results", "campaignresults")

      onSnapshot(dashboardJobSeekersRef, (doc) => {
        const resultData = doc.data();
        if (resultData) {
          this.funnelJobSeekersData = {
            invited: resultData.invited ? resultData.invited : 0,
            screeningStarted: resultData.screeningStarted ? resultData.screeningStarted : 0,
            screeningPassed: resultData.screeningPassed ? resultData.screeningPassed : 0,
            screeningFailed: resultData.screeningFailed ? resultData.screeningFailed : 0,
            mainsStarted: resultData.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted: resultData.interviewSubmitted ? resultData.interviewSubmitted : 0,
          }
        }
      })
    },


    searchData(search) {
      if (this.appliedFilter == true) {
        this.searchedResults = this.filteredTeachers.filter((teacher) =>
          new RegExp(search, "i").test(teacher.firstName) ||
          new RegExp(search, "i").test(teacher.lastName) ||
          new RegExp(search, "i").test(`${teacher.firstName} ${teacher.lastName}`)
        );
      }
      else {
        this.searchedResults = this.topTeacherInCluster.filter((teacher) =>
          new RegExp(search, "i").test(teacher.firstName) ||
          new RegExp(search, "i").test(teacher.lastName) ||
          new RegExp(search, "i").test(`${teacher.firstName} ${teacher.lastName}`))
      }
      this.appliedSearch = true;
    },

    showfilterdialogue() {
      this.filterDialog = true;
      // this.getLeague();
      // console.log(this.displayedClusterTeacher);
    },

    closeFilter() {
      this.filterDialog = false;
    },

    filterTeachers(selectedStatus) {
      this.filteredTeachers = [];
      for (const teacher of this.topTeacherInCluster) {

        for (const status of selectedStatus) {
          if (teacher.status == status) {
            this.filteredTeachers.push(teacher);
          }
        }
      }
      this.appliedFilter = true;
      this.filterDialog = false;
    },

    clearFilter() {
      this.appliedFilter = false;
      this.selectedStageFilter = "",
        this.filterCount = 0,
        this.filteredTeachers = [];
      // this.filterDialog = false;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedStageFilter.length > 0) {
        this.filterCount++;
      }
      // if (this.selectedLeagueFilter.length > 0) {
      //   this.filterCount++;
      // }
    },

    downloadJobSeekersAppraisal() {
      const fileName = "JobSeekersAppraisal.csv";
      const dataForDownload = [];
      for (const teacher of this.topTeacherInCluster) {
        const fullName = `${teacher.firstName} ${teacher.lastName}`;
        const campaign = teacher.campaignName;
        const schoolName = teacher.schoolName ? teacher.schoolName : "-";
        const campstat = teacher.stage ? teacher.stage : "-"

        dataForDownload.push({
          FullName: fullName,
          Campaign: campaign,
          SchoolName: schoolName,
          CurrentStageStatus: campstat
        });
      }
      this.downloadCSV(dataForDownload, fileName);
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },


    async setupSnapshotListenerJobSeekerFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "jobSeekerCampaign"
      );
      //console.log("inside snapshot", id);
      if (id === undefined) {
        //console.log("isnide if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData
        //console.log("isnide snap", this.jobSeekerChartData);
      }

      const data = await getDocs(id);
      //console.log("data>>", data);
      if (data === undefined) {
        //console.log("isnide if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData
        //console.log("isnide snap", this.jobSeekerChartData);
      }
      //console.log("get Docs", data.docs[0]);
      const document = data.docs[0]
      if (document === undefined) {
        //console.log("isnide if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData
        //console.log("isnide snap", this.jobSeekerChartData);
      } else {
        const docRef = doc(id, data.docs[0].id);

        onSnapshot(docRef, (snapshot) => {
          let funnelData = snapshot.data();

          if (
            funnelData.jobSeekerInvited === undefined ||
            funnelData.jobSeekerScreeningStarted === undefined ||
            funnelData.jobSeekerScreeningPassed === undefined ||
            funnelData.jobSeekerScreeningFailed === undefined ||
            funnelData.jobSeekerMainsStarted === undefined ||
            funnelData.jobSeekerMainsPassed === undefined ||
            funnelData.jobSeekerMainsFailed === undefined
          ) {
            //console.log("isnide if");
            this.jobSeekerChartData = this.emptyJobSeekerChartData
            //console.log("isnide snap", this.jobSeekerChartData);

          } else {
            //console.log("isnide else:", funnelData);
            this.jobSeekerChartData = funnelData;

            //console.log("inisde snap job", this.jobSeekerChartData);
            this.recommended = funnelData.jobSeekerMainsPassed;
            this.jobSeekerRejected = funnelData.jobSeekerMainsFailed;
            this.jobSeekerTBD = funnelData.jobSeekerScreeningFailed;
          }

        });
      }
    },

    calculatePercentage(value, total) {
      if (total === 0) {
        // //console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // //console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // //console.log(roundedPercentage);
      return roundedPercentage;
    },

    // async jobSeekerSchoolSnapshotListener() {
    //   const campaignRef = query(
    //     collection(db, "campaigns"),
    //     where("audienceType", "==", "JOB_SEEKER")
    //   );

    //   onSnapshot(campaignRef, async (snapshot) => {
    //     this.passedJobSeekersCount = 0;
    //     this.rejectedJobSeekersCount = 0;
    //     this.tdbJobSeekersCount = 0;
    //     this.otherJobSeekersCount = 0;
    //     this.jobSeekerList = [];

    //     const campaignPromises = snapshot.docs.map(async (doc) => {
    //       const campaignData = doc.data();
    //       const campaignName = campaignData.name;

    //         try {
    //           const jobSeekerRef = collection(db, "campaigns", doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           const jobSeekerPromises = jobSeekDocs.docs.map(async (d) => {
    //             const jobSeekerData = d.data();
                  
    //             if (jobSeekerData?.campaignStatus?.length === 5) {
                
    //               this.updateJobSeekerCounts(jobSeekerData);
    //               await this.processPassedJobSeeker(jobSeekerData, campaignName, doc.id);
    //             }
    //           });

    //           await Promise.all(jobSeekerPromises);
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //     });

    //     await Promise.all(campaignPromises);
    //     this.topTeacherInCluster = this.jobSeekerList;
    //   });
    // },
    
async jobSeekerSchoolSnapshotListener() {
  let queryRef;
  if (this.id) {
    // If campaignId is provided, create a reference to a single document
    queryRef = doc(db, "campaigns", this.id);
  } else {
    // If no campaignId is provided, create a query for all JOB_SEEKER campaigns
    queryRef = query(
      collection(db, "campaigns"),
      where("audienceType", "==", "JOB_SEEKER")
    );
  }

  onSnapshot(queryRef, async (snapshot) => {
    this.passedJobSeekersCount = 0;
    this.rejectedJobSeekersCount = 0;
    this.tdbJobSeekersCount = 0;
    this.otherJobSeekersCount = 0;
    this.jobSeekerList = [];

    const processCampaign = async (doc) => {
      const campaignData = doc.data();

      this.campaignName = this.id ? campaignData.name : "";
      const campaignName = campaignData.name;

      try {
        const jobSeekerRef = collection(db, "campaigns", doc.id, "audience");
        const jobSeekDocs = await getDocs(jobSeekerRef);

        const jobSeekerPromises = jobSeekDocs.docs.map(async (d) => {
          const jobSeekerData = d.data();
            
          if (jobSeekerData?.campaignStatus?.length === 5) {
            this.updateJobSeekerCounts(jobSeekerData);
            await this.processPassedJobSeeker(jobSeekerData, campaignName, doc.id);
          }
        });

        await Promise.all(jobSeekerPromises);
      } catch (error) {
        console.error("Error processing campaign:", error);
      }
    };

    if (this.id) {
      // If we're querying a single document
      if (snapshot.exists()) {
        await processCampaign(snapshot);
      } else {
        console.log("No such document!");
      }
    } else {
      // If we're querying multiple documents
      const campaignPromises = snapshot.docs.map(processCampaign);
      await Promise.all(campaignPromises);
    }

    this.topTeacherInCluster = this.jobSeekerList;
  });
},

  updateJobSeekerCounts(jobSeekerData) {
  if (jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
    this.rejectedJobSeekersCount++;
  }

  if (jobSeekerData.campaignStatus[2].status === "SCREENING FAILED" || jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
    this.tdbJobSeekersCount++;
  }

  if (jobSeekerData.campaignStatus[2].status === "SCREENING FAILED") {
    this.otherJobSeekersCount++;
  }
},

async processPassedJobSeeker(jobSeekerData, campaignName, campaignId) {
  if (jobSeekerData?.demoStatus) {
    let flag = true;
    // if (jobSeekerData?.coreSkillsSubject?.length > 0) {
          // flag = false;
    this.passedJobSeekersCount++;
    await this.addJobSeekerToList(jobSeekerData, campaignName, campaignId);
    // }
    if (flag) {
      this.otherJobSeekersCount++;
    }
  }
},

async addJobSeekerToList(jobSeekerData, campaignName, campaignId) {
  // const screeningTotal = this.calculateTotalScore(jobSeekerData.coreSkillsSubjectScreening);
  // const mainsTotal = this.calculateTotalScore(jobSeekerData.coreSkillsSubject);
  let latestStatus = jobSeekerData.campaignStatus
      ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status
      : "INVITED";
  latestStatus = latestStatus === "MAINS PASSED" ? "PASSED" : "FAILED";
  console.log(latestStatus);
  const temp = {
    firstName: jobSeekerData?.personalInfo?.firstName || "",
    lastName: jobSeekerData?.personalInfo?.lastName || "",
    campaignName: campaignName,
    schoolName: Array.isArray(jobSeekerData?.teachingInterests?.schools)
      ? jobSeekerData?.teachingInterests?.schools[0]
      : jobSeekerData?.teachingInterests?.schools,
    screeningTotal: jobSeekerData.screeningTotal,
    mainsTotal: jobSeekerData.mainsTotal,
    status: latestStatus,
    stage: this.getStage(jobSeekerData.campaignStatus),
    campaignId: campaignId,
    userId: jobSeekerData.userId,
  };

  this.jobSeekerList.push(temp);
},

calculateTotalScore(subjects) {
  if (!subjects || subjects.length === 0) return 0;

  const total = subjects.reduce((sum, subject) => sum + parseFloat(subject?.finalNetScore || 0), 0);
  return total / subjects.length;
},

getStage(campaignStatus) {
  const lastStatus = campaignStatus[campaignStatus.length - 1].status;
  if (["SCREENING PASSED", "SCREENING FAILED"].includes(lastStatus)) return "Screening";
  if (["MAINS PASSED", "MAINS FAILED"].includes(lastStatus)) return "Mains";
  return "Invited";
},
  },

  created() {
    this.id = this.$route.query.id;
    this.jobSeekerSchoolSnapshotListener();
    // this.setupSnapshotListenerJobSeekerFunnel();
    this.newJobSeekerFunnelChartDataListener();
  },

};
</script>

<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}

.theme--light.success-01.v-chip.v-chip--no-color.v-chip--outlined {
  background-color: var(--success-01) !important;
}
</style>